import { useUser } from '../composables/states';
import { type Permission } from '../../shared/shared.interface';
const config = useRuntimeConfig();

export const useGetRolePermissions = async(roleId: string) => {
    if (!roleId)
        return [];

    const requestUrl = `${config.public.apiBase}/roles-permissions/permissions/${roleId}`;
    const { data: response } = await useFetch(
        requestUrl,
        {
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        }
    );
    return response._rawValue;
};

export const isUserPermitted = (userPermissionName: string) => {
    const user = useUser().value;
    const userPermissions = user.permissions;
    if (!userPermissions.length)
        return false;

    const permission = userPermissions.filter(userPermission => userPermission.name === userPermissionName);

    return Boolean(permission.length);
};

export const useGetSelectedPermissions = async(roleId:string) => {
    const rolePermissions:Permission[] = await useGetRolePermissions(roleId);
    const permissions:Permission[] = await useGetPermissions();

    if (!rolePermissions.length)
        return permissions;

    permissions.forEach((permission) => {
        rolePermissions.forEach((rolePermission) => {
            if (rolePermission._id === permission._id)
                permission.selected = true;
        });
    });

    return permissions;
};

export const useUpdateUserPermissions = async(roleId: string) => {
    useUser().value.permissions = await useGetRolePermissions(roleId);
};

export const useGetPermissions = async() => {
    const requestUrl = `${config.public.apiBase}/permissions`;
    const { data: response } = await useFetch(
        requestUrl,
        {
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        }
    );
    return response._rawValue;
};
